@import '../../styles/propertySets.css';


/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: sticky;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 2; /* increased because of Xmas images. Orgininal value = 1  */
  }
}

.containerMapClosed {
  display: flex;
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  margin: auto;
  max-width: var(--maxContentWidth);

  @media (--viewportMedium) {
    position: relative;
    /* padding-top: var(--topbarHeightDesktop); */
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 70%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 0px;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    padding: 0 36px;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;
  display: flex;

  @media (--viewportLarge) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.searchFiltersCategories {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.searchFiltersCategoriesMobile {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.mapPanel {
  @media (--viewportLarge) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 37.5%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportLarge) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 37.5vw;
    height:calc(100vh - var(--topbarHeightDesktop));
  }
}


/* SearchPage hero section */


:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 800px;
  --SectionHero_desktopTitleMaxWidthLarge: 1024px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
    margin-bottom: 30px;
  }
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* @apply --backgroundImage; */

  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  min-height: 200px;
  @media (--viewportLarge){
    min-height: 350px;
  }
  /* Text positioning is done with paddings */
}

.heroContent {
  margin: auto;
  opacity: 1;
  height: 100%;
  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
  &::before {
    background: linear-gradient(0deg, rgba(26,26,26,1) 0%, rgba(255,255,255,0) 56%);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    bottom:0px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  text-align: center;

  composes: animation;
  animation-delay: 0.5s;

  margin-bottom: 16px;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    text-align: left;
  }
  @media (--viewportLarge) {
    max-width: var(--SectionHero_desktopTitleMaxWidthLarge);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  text-align: center;
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    text-align: left;
    margin: 0 0 48px 0;
  }
  @media (--viewportLarge) {
    max-width: var(--SectionHero_desktopTitleMaxWidthLarge);
  }
}

.heroButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  width: 100%;
  max-width: 525px;
  margin-bottom: 56px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 560px;
  }
}

.imgWrapper{
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
  animation-name: animationImg;
  animation-duration: 1.6s;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;

}

@keyframes animationImg {
  0% {
    /* visibility: hidden; */
    opacity: 0;
  }
  100% {
    /* visibility: visible; */
    opacity: 1;
  }
}

.imgWrapper img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

/* Destinations section */

.destinationWrapper {
  padding: 0 24px;
  display: flex;
  max-width: 1024px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  @media (--viewportLarge) {
    padding: 0px;
  }
}

.searchFiltersCategoriesWrapper {
  display: grid;
  padding: 0 24px;
  position: relative;
  z-index: 1;
  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
